import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import RightSide from '../components/rightside-general'

import BG from '../images/awards-bg.jpg'

class Testimonials extends React.Component {
	render() {
		const siteTitle = 'Testimonials'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3>Testimonials</h3>
								<p>
									“David Shaw has been our accountant since late 2005 after a
									recommendation from a friend that knew we were looking around
									for a property savvy accountant. Initially Kerry &amp; I
									travelled from the Hunter Valley a couple of times a year to
									meet with David to discuss our tax return planning and our
									annual PAYG tax variation, but now he travels to Newcastle
									around tax time for his client base here, which is great!
								</p>
								<p>
									David has helped Kerry &amp; I with many property, accounting
									and taxation issues over the years including the set-up of our
									SMSF and the acquisition of properties both inside and outside
									our fund. David also plays an integral role in our decision
									making process by providing us with a big picture view of our
									options when we are planning for the year ahead. David’s help
									&amp; expertise are invaluable to our wealth building
									strategy, though our investment property portfolio”
								</p>
								<p>
									<strong>Steve &amp; Kerry Bishop</strong>
								</p>
								<hr />
								<p>
									We have been utilising the services of the David Shaw and
									Sarah Cox since 2009. Throughout our relationship, David’s
									knowledge and passion of all things property-related has
									helped us feel confident about our property portfolio.
								</p>
								<p>
									David and Sarah are professional, yet personable, and always
									explain issues to us in ways that help us to understand our
									tax and property affairs.
								</p>
								<p>
									<strong>Michael and Anthea Newton</strong>
								</p>
								<hr />
								<p>
									I have had the pleasure utilizing Catherine Simons’ services
									about six years ago when I changed accountants and have not
									looked back since. From my personal tax returns to my
									company’s returns and advice in general, Catherine has always
									been there to assist and give guidance on best practices. I
									have found over the years Catherine to be very professional,
									ethical and above all very approachable with any questions I
									may have.
									<br />
									<br />I would recommend Catherine and WSC Group for anyone
									that is looking for excellent accountants- look no further!
								</p>
								<p>
									<strong>Michael Brahim</strong>
								</p>
								<hr />
								<p>
									We changed our accountants 6 years ago and I have dealt with
									Lindsay Davis from WSC Group Menai ever since then. From the
									outset Lindsay has proved to be a real asset to our business.
									He is personable, very knowledgeable and when he explains
									things to me he does so in layman’s terms so I completely
									understand.
								</p>
								<p>
									On numerous occasions I have either called or emailed Lindsay
									with questions and he always gets back to me with answers and
									advice.
								</p>
								<p>
									Our tax returns are always done in a timely manner and
									extremely professional. We also have a tax planning meeting a
									couple of months before the end of the financial year so that
									Lindsay can make sure that we do things to get better tax
									advantages. Nothing seems too much trouble for him.
								</p>
								<p>
									For anyone looking for a top class accountant, I can
									thoroughly recommend you give Lindsay a try… you won’t be
									disappointed.
								</p>
								<p>
									<strong>Judi Burke</strong>
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default Testimonials

export const TestimonialsPageQuery = graphql`
	query TestimonialsPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
